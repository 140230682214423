<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 课程列表 </template>
      <template #input>
        <a-input v-model="courseName" placeholder="请输入课程名称" />
        <a-button class="all_boder_btn btn" type="primary" @click="page[tabIndex].pageNumber=1;getList()">搜索</a-button>

        <router-link
          to=""
          @click.native="$router.push('/admin/curriculum/GeneralView/BasicInformation?status=1')"
          tag="a-button"
          class="all_boder_btn"
          v-hasPermi="['class:list:query']"
          >新建</router-link
        >
      </template>
    </HeaderBox>

    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs v-model="tabIndex">
        <a-tab-pane v-for="(item, index) in page" :key="index" :tab="item.name">
          <a-table
            class="table-template"
            :rowKey="(item) => item.courseId + '/' + index"
            :columns="item.columns"
            :data-source="item.tableData"
            :loading="item.loading"
            @change="onPage"
            :pagination="{
              total: item.total,
              current: item.pageNumber,
              defaultPageSize: item.pageSize,
              showSizeChanger: true,
              showTotal: function (total, range) {
                return `共${total}条`;
              },
            }"
          >
            <template slot="index" slot-scope="e, row, i">
              <div style="text-align: center">
                {{ (item.pageNumber - 1) * 10 + i + 1 }}
              </div>
            </template>

            <!-- 课程名称 -->
            <template slot="courseName" slot-scope="item">{{item==''?'-':item}}</template>

            <!-- 课程类别 -->
            <template slot="courseCategoryName" slot-scope="item">{{item==null?'-':item}}</template>

            <!-- 总课时 -->
            <template slot="classHour" slot-scope="item">
              {{ item }}视频
            </template>

            <!-- 优惠价 -->
            <template slot="couponPrice" slot-scope="item">
              {{ item==0?'免费':item==undefined?'-':'¥'+onThousand(item) }}
            </template>

            <!-- 是否支持预览 -->
            <template slot="isFlag" slot-scope="item">
              <a-switch v-hasPermi="['class:publist:updateflag']" @change="onChangeFlag(item)" v-model="item.flag" />
            </template>

            <!-- 状态 1.暂存 2.上架 3.下架 -->
            <template slot="status" slot-scope="item">
              <span class="state_color_yellow" v-if="item == 1">已暂存</span>
              <span class="state_color_green" v-else-if="item == 2">已上架</span>
              <span class="state_color_red" v-else-if="item == 3">已下架</span>
            </template>

            <template slot="operation" slot-scope="item, row">
              <div class="btn_router_link">
                <a v-if="false && row.courseCategoryName == '线下培训'" @click="downCourse(row)">线下课程补充</a>
                <span v-if="false && row.courseCategoryName == '线下培训'">|</span>
                <a @click="editCourseware(row)">课件</a>
                <span>|</span>
                <a @click="editContents(row.courseId, row.videoFinishFlag)">目录维护</a>
                <span>|</span>
                <a @click="editData2(row)" v-hasPermi="['class:info:update']">编辑</a>
                <span v-hasPermi="['class:info:update']">|</span>
                <a @click="reviewData(row)" >预览</a>
                <span v-if="row.status != 2">|</span>
                <!-- <a @click="changeShelf(row.courseId,row.status)" v-if="row.status != 1">{{row.status==2 ? '下架':'上架'}}</a>
                <span v-if="row.status != 1 && row.status != 2">|</span> -->
                <a v-if="row.status != 2" @click="removeData(row.courseId)">删除</a>
              </div>
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>

      <a-empty v-if="page.length == 0" style="padding: 100px 0" />
    </div>
    
    <!-- 线下课程补充 -->
    <a-modal v-model="visible" title="线下课程补充" :confirm-loading="confirmLoading" @ok="editdownCourse()" okText="确认">
      <!-- 根分类 -->
      <template>
        <a-row class="rows">
          <a-col span="5" class="left">报名时间</a-col>
          <a-col span="19" class="right">
            <a-range-picker
              valueFormat="YYYY-MM-DD HH:mm:ss"
              showTime
              v-model="lineDowntime"
            ></a-range-picker>
          </a-col>
        </a-row>
      </template>
    </a-modal>

    <!-- 课程预览 -->
    <a-modal v-model="reviewVisible" :width="375" class="reviewmodel" title="课程预览" @cancel="reviewUrl = null" :footer="false">
      <div class="wxreview">
        <p>预览链接：（仅支持在微信环境预览）<span @click="copyText2" class="copy">复制</span></p>
        <span class="a">{{wxReviewUrl}}</span>
      </div>
      <iframe :width="375" :height="700" v-if="reviewUrl" :src="reviewUrl" frameborder="0"></iframe>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import { onThousand } from "@/unit/fun.js";
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "课程名称",
    align: "center",
    dataIndex: "courseName",
    scopedSlots: { customRender: "courseName" },
  },
  {
    title: "课程类别",
    align: "center",
    dataIndex: "courseCategoryName",
    scopedSlots: { customRender: "courseCategoryName" },
  },
  {
    title: "总课时",
    align: "center",
    dataIndex: "classHour",
    scopedSlots: { customRender: "classHour" },
  },
  {
    title: "优惠价",
    align: "center",
    dataIndex: "couponPrice",
    scopedSlots: { customRender: "couponPrice" },
  },
  {
    title: "销量",
    align: "center",
    dataIndex: "salesVolume",
  },
  {
    title: "是否可预览",
    align: "center",
    scopedSlots: { customRender: "isFlag" },
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    align: "center",
    width: "350px",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      courseCategoryId: '',
      onThousand,
      page: [],
      state: 1, // 1新建，2编辑
      visible: false,
      tabIndex: 0,
      templateType: "1", // 模版类型
      key: null,
      courseName: '', // 课程名称

      confirmLoading: false,
      row: null, // 补充资料
      lineDowntime: [], // 线下培训报名时间
      id: '', // 主键id

      // 预览
      reviewUrl: null, // 地址
      wxReviewUrl: null, // 微信H5地址
      reviewVisible: false, // 弹框控制
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      let page = this.page[this.tabIndex];
      page.pageNumber = e.current;
      page.pageSize = e.pageSize;
      this.getList();
    },

    // 控制课程预览
    onChangeFlag(e){
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/course/updateFlagBayCourseId?courseId=" + e.courseId + "&flag=" + (e.flag ? 1 : 0),
        method: 'PUT',
      }).then((res) => {
        this.confirmLoading = false
        if (res.code == 200 && res.success) {
          this.getList();
          this.$message.success('设置成功！')
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      })
    },

    // 线下课程补充资料详情
    downCourse(e) {
      this.row = e;
      this.$ajax({
        url: "/hxclass-management/course/getCourseExtends",
        params: {
          courseId: e.courseId
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(res.data) {
            this.id = res.data.id
            this.lineDowntime = [res.data.startTime,res.data.endTime]
          }else {
            this.id = ''
            this.lineDowntime = []
          }
          this.visible = true;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 线下课程补充资料编辑
    editdownCourse(e) {
      if(!this.lineDowntime.length){
        return this.$message.error('请选择报名时间！');
      }
      this.confirmLoading = true
      this.$ajax({
        url: "/hxclass-management/course/saveAndUpdateCourseExtendsInfo",
        method: 'post',
        params: {
          courseId: this.row.courseId,
          startTime: this.lineDowntime[0],
          endTime: this.lineDowntime[1],
          id: this.id
        }
      }).then((res) => {
        this.confirmLoading = false
        if (res.code == 200 && res.success) {
          this.visible = false;
          this.$message.success('设置成功！')
        } else {
          this.$message.error(res.message);
        }
      })
    },

    // 跳详情页
    goDetails() {
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: { id: 1 },
      });
    },

    // 查询分类
    getQueryClass() {
      let key = (this.key = "getQueryClass");
      this.$message.loading({ content: "正在查询...", key, duration: 0 });
      this.$ajax({
        url: "/hxclass-management/course/category/course",
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            let list = res.data.filter((item) => {
              // item.name // 分类名称
              // item.courseCategoryId // 分类id
              item.columns = columns; // 表头
              item.tableData = []; // 数据
              item.loading = false; // 加载状态
              item.total = 0; // 总条数
              item.pageNumber = 1; // 页码
              item.pageSize = 10; // 页数
              return item;
            });
            list = [
              {
                name: "全部课程",
                courseCategoryId: 0,
                columns: columns,
                tableData: [],
                loading: false,
                total: 0,
                pageNumber: 1,
                pageSize: 10,
              },
              ...list,
            ];
            this.page = list;
            this.getList();
            setTimeout(() => {
              this.$message.success({ content: "查询成功!", key, duration: 1 });
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    

    // 查询列表
    getList() {
      let page = this.page[this.tabIndex];
      this.courseCategoryId = page.courseCategoryId
      page.loading = true;
      this.$ajax({
        url: "/hxclass-management/course/",
        params: {
          courseCategoryId: page.courseCategoryId,
          courseName: this.courseName,
          pageNum: page.pageNumber,
          pageSize: page.pageSize,
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            if(res.data.records.length){
              res.data.records.forEach(element => {
                element.flag = element.flag == 1 ? true : false
              });
            }
            page.tableData = res.data.records;
            page.total = res.data.total;
          } else {
            this.$message.error(res.message);
          }
          setTimeout(() => {
            page.loading = false;
          }, 300);
        })
        .catch((err) => {
          setTimeout(() => {
            page.loading = false;
          }, 300);
        });
    },

    // 上下架
    changeShelf(id,status){
      let form = {
        courseId: id,
        status: status == 3 ? 2 : 3,
      }
      this.$ajax({
        url:"/hxclass-management/course/manage/change-status",
        method:'put',
        params: form
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.getList();
        }else{
          if(res.code == -6){
            let _this = this;
            return this.$message.error("该课程已关联重磅课程，无法下架，请先取消关联！");
            this.$confirm({
              title: "该课程已关联重磅课程，是否下架？",
              okText: "确认",
              onOk() {
                let page = _this.page[_this.tabIndex];
                page.loading = true;
                _this
                  .$ajax({
                    url: `/hxclass-management/course/down?code=`+res.code+'&courseId='+id,
                    method: "put",
                    // params: {
                    //   code:res.code, //	下架编码
                    //   courseId:id, //
                    // }
                  })
                  .then((res) => {
                    if (res.code == 200 && res.success) {
                      _this.getList();
                    } else {
                      _this.$message.error(res.message);
                    }
                    setTimeout(() => {
                      page.loading = false;
                    }, 300);
                  })
                  .catch((err) => {
                    setTimeout(() => {
                      page.loading = false;
                    }, 300);
                  });
              },
              onCancel() {},
            });
            return;
          }
          this.$message.error(res.message);
        }
      })
    },

    // 删除课程
    removeData(e) {
      let _this = this;
      this.$confirm({
        title: "确定删除该课程吗?",
        okText: "确认",
        onOk() {
          let page = _this.page[_this.tabIndex];
          page.loading = true;
          _this
            .$ajax({
              url: `/hxclass-management/course/${e}`,
              method: "delete",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.getList();
              } else {
                _this.$message.error(res.message);
              }
              setTimeout(() => {
                page.loading = false;
              }, 300);
            })
            .catch((err) => {
              setTimeout(() => {
                page.loading = false;
              }, 300);
            });
        },
        onCancel() {},
      });
    },

    // 编辑课程
    editData(id) {
      this.$router.push(
        `/admin/curriculum/CourseDetails?courseId=${id}&state=2`
      );
    },

    // 编辑课程
    editData2(e) {
      this.$router.push(
        `/admin/curriculum/GeneralView/BasicInformation?id=${e.courseId}&status=${e.status}`
      );
    },

    // 课程预览
    reviewData(e) {
    console.log()
      // 开发环境
      if(this.$config.baseUrl == 'api') {
        this.reviewUrl = 'https://almobile.hxclass.cn/curriculum/course?id=' + e.courseId;
        this.wxReviewUrl = 'https://alwx.hxclass.cn/pages/curriculum/review/course?id=' + e.courseId;
      }
      // 测试环境
      else if(window.location.href.indexOf('https://almanage') != -1) {
        this.reviewUrl = 'https://almobile.hxclass.cn/curriculum/course?id=' + e.courseId;
        this.wxReviewUrl = 'https://alwx.hxclass.cn/pages/curriculum/review/course?id=' + e.courseId;
      }
      // 正式环境
      else {
        this.reviewUrl = 'https://mobile.hxclass.cn/curriculum/course?id=' + e.courseId;
        this.wxReviewUrl = 'https://wx.hxclass.cn/pages/curriculum/review/course?id=' + e.courseId;
      }
      this.reviewVisible = true;
    },

    // 复制：方法二
    copyText2() {
      let inputDom = document.createElement('textarea') // js创建一个文本框
      document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
      inputDom.value = this.wxReviewUrl //将需要复制的内容赋值到创建的文本框中
      inputDom.select() //选中文本框中的内容
      inputDom.focus()
      document.execCommand('copy') //执行复制操作
      document.body.removeChild(inputDom) //最后移出
      this.$message.success('复制成功')
    },

    // 目录维护
    editContents(id,videoFinishFlag) {
      this.$router.push(
        `/admin/curriculum/CourseContents?courseId=${id}&videoFinishFlag=${videoFinishFlag}`
      );
    },
    // 课件维护
    editCourseware(e) {
      this.$router.push(
        `/admin/curriculum/Courseware?courseId=${e.courseId}&courseName=${e.courseName}`
      );
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getQueryClass();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    tabIndex(e) {
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.rows{
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
  .left{
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before{
      content: '*';
      color: red;
      margin-right: 2px;
    }
    &::after{
      content: '：';
    }
  }
  .flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input{
      flex: 1;
    }
    a{
      margin-left: 8px;
    }
  }
}
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.ant-tabs {
  overflow: visible;
}
.table {
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
.reviewmodel{
  /deep/.ant-modal-body{
    padding: 0;
  }
  .wxreview{
    padding: 10px;
    .a{
      text-decoration: underline;
      font-size: 11px;
    }
    .copy{
      color: #3681F0;
      cursor: pointer;
    }
  }
}
</style>
